<script>
import store from '@/store'
import WaitingDialog from '@/components/WaitingDialog'
import QRReader from '@/components/QRReader'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import DoorSelector from '@/components/DoorSelector'
import { SideBtnType } from '@/store'
import { publish, MQTT_ACTION } from '@/utils/MQTTClient.js'
import i18n from '@/i18n'

export const TransTypes = {
    Delivery: 1,
    Leave: 2,
    LongLeave: 3,
    StoreHost: 4,
    WashClient: 5,
    WashClerk: 7,
    APIClient: 8,
    DocsClient: 9,
    DocsClerk: 11
}

export default {
    components: { WaitingDialog, QRReader, DoorSelector },
    mixins: [ WaitDialogControl ],
    props: {
        box: {
            type: String,
            default: ''
        },
        door: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            titles: [],
            preStepNo: 0,
            stepNo: 1,
            clerk: false,
            trackNo: '',
            doorId: 0,
            doorInfo: null,
            rate: null,
            camera: 'off',
            isFromLinePay: false,
            action: MQTT_ACTION.PUTIN_ACTION
            // showGoButtomBtn: false
        }
    },
    watch: {
        boxId() {
            if(this.isFromLinePay) return;
            if (this.boxId) {
                console.log('[putin mixin] stepNo:'+this.stepNo)
                if (this.stepNo === 2) {
                    this.cammera = 'off'
                    this.clerk = true
                    this.setStepNo(3)
                } else this.setStepNo(2)
            }
            // if (this.boxId) {
                // console.log('enter boxId')
                // await this.initShowButtomBtn()
                // await this.setStepNo(2)
                // // await this.getPageTwoHeight()
                // await setTimeout(() => {
                //     console.log('enter setTimeout')
                //     let pageTwoElement = document.getElementById('pageTwo')
                //     console.log('pageTwoElement', pageTwoElement)
                //     console.log('pageTwoElement.display', pageTwoElement.style.display)

                //     let pageTwoHeight = (pageTwoElement.clientHeight + 128)
                //     console.log('pageTwoElement.clientHeight', pageTwoElement.clientHeight)
                //     console.log('pageTwoElement.offsetHeight', pageTwoElement.offsetHeight)
                //     console.log('pageTwoHeight', pageTwoHeight)
                //     console.log('document.documentElement.clientHeight', document.documentElement.clientHeight)

                //     // let testElement = document.getElementById('test')
                //     // console.log('testElement', testElement)
                //     // let testHeight = testElement.clientHeight
                //     // console.log('testHeight', testHeight)

                //     if(pageTwoHeight > document.documentElement.clientHeight) {
                //         this.showGoButtomBtn = true
                //         console.log('enter change showGoButtomBtn', this.showGoButtomBtn)
                //     }
                // }, 400)
            // }
        },
        controlResult() {
            this.closeDialog()

            //這邊直接檢查isFromLinePay可以做調整(line pay 付款成功到第四頁後，按繼續投件也需要用isFromLinePay擋住，不然會跳轉到第二步驟 ) 
            let controlOk = this.checkControl()
            if (controlOk) {
                if (this.isFromLinePay) this.setStepNo(4)
                else this.nextStep()
            }
        }
    },
    computed: {
        doorRate() {
            if (this.rate && this.doorInfo) {
                let styleRate = this.rate.rates[this.doorInfo.style]
                let work_hour = this.rate.work_hour
                //console.log("work_hour:"+this.rate.work_hour)
                if (work_hour === undefined) {
                    work_hour = this.rate.back_hour - this.rate.take_hour
                }
                if (styleRate.original_rate === undefined) {
                    return {
                        backHour: this.rate.back_hour,
                        takeHour: this.rate.take_hour,
                        workHour: work_hour,
                        startUnit: this.rate.start_unit,
                        contiUnit: this.rate.conti_unit,
                        originalRate: 0,
                        startRate: styleRate.start_rate,
                        contiRate: styleRate.conti_rate,
                        monthRate: styleRate.month_rate
                    }
                } else {
                    return {
                        backHour: this.rate.back_hour,
                        takeHour: this.rate.take_hour,
                        workHour: work_hour,
                        startUnit: this.rate.start_unit,
                        contiUnit: this.rate.conti_unit,
                        originalRate: styleRate.original_rate,
                        startRate: styleRate.start_rate,
                        contiRate: styleRate.conti_rate,
                        monthRate: styleRate.month_rate
                    }
                }
            } else return {}
        }
    },
    methods: {
        init() {
            store.commit('initTransaction')
            store.commit('setTargetBoxId', '')
            store.commit('setMQTTPayload', null)
            this.boxId = ''
            this.preStepNo = 0
            this.stepNo = 1
            store.commit('setTitle', this.titles[this.stepNo - 1])
            this.trackNo = ''
            this.doorId = 0
            this.doorInfo = null
            this.isFromLinePay = store.commit('setIsFromLinePay', false)
            this.isFromLinePay = false
            this.emptyDialog = false
            this.reloadPage = false
            this.dialogMessage = i18n.t('putIn.cabinet-unavailable')
        },
        setStepNo(no) {
            console.log('[putin mixin]setStepNo', no)
            if (no === 1) {
                this.init()
                this.camera = 'auto'
            } else if (no === 2 & this.clerk == true) {
                this.init()
                this.cammera = 'auto'
                this.stepNo = 2
            } else this.camera = 'off'
            this.preStepNo = this.stepNo
            this.stepNo = no
            store.commit('setTitle', this.titles[this.stepNo - 1])
        },
        nextStep() {
            this.setStepNo(Math.min(this.stepNo + 1, this.titles.length))
        },
        preStep() {
            this.setStepNo(Math.max(this.stepNo - 1, 1))
        },
        onQuery(query) {
            store.commit('setTargetBoxId', query.box_id)
            this.boxId = query.box_id
            this.doorId = query.door_id
        },
        selectDoor(door, rate) {
            this.doorInfo = door
            this.rate = rate
        },
        reopen: function() {
            this.showDialog(i18n.t('putIn.door-control') + ' ' +this.doorId, i18n.t('putIn.door-control-fail'))
            var topic = 'box/' + store.getters.targetBoxId + '/trans/control';
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId,
                action: MQTT_ACTION.PUTIN_ACTION,
                track_no: {[this.trackNo]: this.doorId}
            }, 1);
        },
        initShowButtomBtn() {
            this.showGoButtomBtn = false
            console.log('initShowButtomBtn', this.showGoButtomBtn)
        },
        // getPageTwoHeight() {
        //     console.log('enter setTimeout')
        //     let pageTwoElement = document.getElementById('pageTwo')
        //     console.log('pageTwoElement', pageTwoElement)
        //     console.log('pageTwoElement.display', pageTwoElement.style.display)
        //     if(pageTwoElement.style.display == 'none') {
        //         console.log('pageTwoElement.style.display', pageTwoElement.style.display)
        //     }
        //     let pageTwoHeight = pageTwoElement.clientHeight + 128
        //     console.log('pageTwoElement.clientHeight', pageTwoElement.clientHeight)
        //     console.log('pageTwoElement.offsetHeight', pageTwoElement.offsetHeight)
        //     console.log('pageTwoHeight', pageTwoHeight)
        //     console.log('document.documentElement.clientHeight', document.documentElement.clientHeight)

        //     let testElement = document.getElementById('test')
        //     console.log('testElement', testElement)
        //     let testHeight = testElement.clientHeight
        //     console.log('testHeight', testHeight)

        //     if(pageTwoHeight > document.documentElement.clientHeight) {
        //         this.showGoButtomBtn = true
        //         console.log('enter change showGoButtomBtn', this.showGoButtomBtn)
        //     }
        // }
    },
    activated() {
        store.commit('setSideBtnType', SideBtnType.Back)
        this.init()
        if (this.box && this.door > 0) this.onQuery({box_id: this.box, door_id: this.door})
        else this.camera = 'auto'
    },
    deactivated() {
        this.camera = 'off'
        store.commit('setSideBtnType', SideBtnType.Navi)
    }
}
</script>
